<template>
    <v-app>
        <div class="row">
            <div class="alert alert-success" role="alert">
              Por lanzamiento de nuestra plataforma la generación de contratos es gratuita. Feedback bienvenido al correo &nbsp;
                .<a href="mailto:info@adjudica.ai" class="alert-link"> info@adjudica.ai</a>
            </div>
            <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
                <KTPortlet v-bind:title="'Documentos Disponibles'">
                    <template v-slot:toolbar>
                        <v-text-field
                                v-model="search"
                                append-icon="search"
                                label="Buscar"
                                single-line
                                hide-details
                        ></v-text-field>
                    </template>
                    <template v-slot:body>
                        <v-container fluid>
                            <v-progress-linear v-if="loading"
                                    indeterminate
                            ></v-progress-linear>
                            <v-row dense>
                                <v-col v-for="(item, i) in items"
                                       :key="i"
                                       cols="4">
                                    <v-card :color="item.color ? item.color : colors[i]" dark>
                                        <v-card-title class="headline" v-text=" item.nombre"></v-card-title>
                                        <v-card-subtitle v-text="item.descripcion" style="height: 40px" ></v-card-subtitle>
                                        <v-card-actions>
                                            <v-list-item class="grow">
                                                <v-list-item-content color="grey darken-3">
                                                    <v-btn outlined @click="newTransaction(item)">Usar
                                                        <v-icon class="mr-1">arrow_right_alt</v-icon></v-btn>
                                                </v-list-item-content>

                                                <v-row align="center"
                                                        justify="end">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on }">
                                                            <span  style="font-size: 20px; font-weight: bold; font-family: 'Arial Rounded MT Bold'" v-on="on" class="subheading">$. {{item.precio}}</span>
                                                        </template>
                                                        <span>Precio del contrato</span>
                                                    </v-tooltip>

                                                </v-row>
                                            </v-list-item>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </template>
                </KTPortlet>
            </div>
        </div>

    </v-app>


</template>

<script>
    import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";


    import KTPortlet from "@/views/partials/content/Portlet.vue";
    import service from "@/service/contract.service"


    export default {
        name: "ContractAvailable",
        components: {
            KTPortlet
        },
        data() {
            return {
                search: '',
                colors: ['pink darken-2','#1F7087', '#952175','indigo darken-2','#26c6da', '#385F73', 'pink darken-2'],
                loading: false,
                contracts: [],
                tabIndex: 0,
                tabIndex2: 0
            };
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{ title: "Documentos"},{title: "Nuevo contrato"}]);
            this.loading = true;
            service.get().then(x => {
                this.contracts = x.data.data.filter(x => { return x.pasos > 0});
                this.loading = false;
            })
        },
        computed: {

            items() {
                return this.contracts.filter(x => { return x.nombre.toUpperCase().includes(this.search.toUpperCase())});
            }
        },

        methods: {
            newTransaction(item) {
                this.$router.replace({ name: 'transaction-register', params : {id: item.id}});
            },
        }
    }
</script>

<style scoped>

</style>
